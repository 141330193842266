import Link from 'components/Globals/Base/Link';
import rawHtml from 'utils/miscUtils/rawHtml';
import { CallToAction } from 'interfaces/content/Ranking';
import { NextCustomImage } from 'components/Image';
import { FEATURED_IMAGE } from 'constants/testsIds/rankingV2Page';
import { parseContentForEmptySpace } from 'utils/parsingUtils/clientSideParsing';
import * as S from './FeatureImageStyles';

interface FeatureImageProps {
  image: string;
  description: string;
  title: string;
  hasMethodology: boolean;
  callToAction: CallToAction[];
}

const FeaturedImage = ({ image, title, description, callToAction, hasMethodology = true }: FeatureImageProps) => {
  const hasCta = callToAction.length > 0;
  const ctaCount = hasMethodology ? callToAction.length + 1 : callToAction.length;
  return (
    <S.Wrapper data-cy={FEATURED_IMAGE.WRAPPER}>
      <S.HeroImage>
        {image ? (
          <NextCustomImage
            src={image}
            alt='generic image for ranking page'
            width={1920}
            height={960}
            className='hero-image'
            data-cy={FEATURED_IMAGE.HERO_IMAGE}
          />
        ) : null}
      </S.HeroImage>
      <S.ContentWrapper>
        <S.Title
          as='h1'
          $fontFamily='graphikCond'
          $size={{ default: 'level1', lgMin: 'xlarge', mdMin: 'large' }}
          className={image ? 'feature-image-title' : ''}
          data-cy={FEATURED_IMAGE.FI_TITLE}
        >
          {title}
        </S.Title>
        {(hasCta || description) !== '' && (
          <S.Content>
            <div data-cy={FEATURED_IMAGE.FI_DESCRIPTION}>
              {rawHtml({ html: parseContentForEmptySpace(description) })}
            </div>
            <S.LinksWrapper className={ctaCount > 2 ? 'row' : ''}>
              {callToAction.map((item, index) => {
                const isMethodology = item.label.toLowerCase() === 'methodology';
                const hasLabel = item.label.trim() !== '';
                return hasLabel ? (
                  <Link
                    key={`${item.label}`}
                    href={item.link}
                    target='_self'
                    type={index === 0 ? 'primaryButton' : 'tertiaryButton'}
                    className={isMethodology ? 'methodology' : ''}
                    hasRightArrow={false}
                    dataCy={`${FEATURED_IMAGE.FI_CTA}-${index}`}
                    size={{ default: 'small', mdMin: 'large' }}
                  >
                    {item.label.toUpperCase()}
                  </Link>
                ) : null;
              })}
              {hasMethodology && (
                <Link
                  key='Methodology'
                  href='#methodology'
                  target='_self'
                  type={ctaCount === 1 ? 'primaryButton' : 'tertiaryButton'}
                  className='methodology'
                  hasRightArrow={false}
                  dataCy={`${FEATURED_IMAGE.FI_CTA}-${callToAction.length + 1}`}
                  size={{ default: 'small', mdMin: 'large' }}
                >
                  Methodology
                </Link>
              )}
            </S.LinksWrapper>
          </S.Content>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default FeaturedImage;
